import CryptoJS from 'crypto-js'
const getDefaultState = () => {
  return {
    isLoggedIn: false,
    jwt_token: '',
    user_id: '',
    user_role: '',
    error: '',
    lmsSession: '',
    lmsIsLoggedIn: false,
    userRegion: 'default',
    tracking: true,
  }
}

export const state = () => getDefaultState()
export const mutations = {
  setUser(state, user) {
    state.isLoggedIn = user.isLoggedIn
    state.jwt_token = user.jwt_token
    state.user_id = user.user_id
    state.user_role = user.user_role
  },
  setLmsUser(state, user) {
    state.lmsIsLoggedIn = user.lmsIsLoggedIn
    state.lmsSession = user.session
  },
  deleteUser(state) {
    Object.assign(state, getDefaultState())
  },
  setError(state, error) {
    state.error = error
  },
  setRegion(state, region) {
    state.userRegion = region
  },
  setTracking(state, status){
    state.tracking = status
  }
}

export const getters = {

  isLoggedIn: (state) => {
    return state.isLoggedIn
  },
  lmsIsLoggedIn: (state) => {
    return state.lmsIsLoggedIn
  },
  jwt: (state) => {
    return state.jwt_token
  },
  id: (state) => {
    return state.user_id
  },
  region: (state) => {
    return state.userRegion
  },
  tracking: (state) => {
    return state.tracking
  }
}
export const actions = {

  // LMS LOGIN
  async lmsLogin({
    commit
  }, data) {
    console.log('LMS LOG IN', process.env.LMS_URL)
    try {
      const result = await this.$axios.$post(
        process.env.LMS_URL + '/extorio/apis/users/login', {
          username: data.username,
          password: data.password,
        }
      )
      result.lmsIsLoggedIn = true
      commit('setLmsUser', result)
      return result
    } catch (error) {
      commit('setLmsError', error.response.data)
      return error.response.data
    }
  },

  // LOGIN
  async login({
    commit
  }, data) {
    console.log('LOG IN', process.env.DATA_URL)
    try {
      let result;
      if (!data.jwt) {

        // Make sure username is lowercase with no whitepsace
        const username = data.username.trim().toLowerCase();
        result = await this.$axios.$post(
          // process.env.DATA_URL + '/auth/customer-login',

          location.protocol + '//' + location.host + '/.netlify/functions/login', {
            username,
            password: data.password,
          }
        )
        result.isLoggedIn = true
        commit('setUser', result)
        return result

      } else {

        result = {
          jwt_token: data.jwt,
          user_id: parseInt(data.userid),
          user_role: 'customer',
          isLoggedIn: true
        }
        commit('setUser', result)
        return result

      }

    } catch (error) {
      commit('setError', error.response.data)
      return error.response.data
    }
  },

  // REGISTER
  async register({
    commit
  }, data) {
    console.log('REGISTER: ', data)
    // Make sure username is lowercase with no whitepsace
    const username = data.username.trim().toLowerCase();
    const registerData = {
      title: null,
      email: username,
      firstname: data.firstname,
      lastname: data.lastname,
      password: data.password,
      line1: null,
      line2: null,
      town: null,
      region: null,
      postcode: null,
      country: data.country,
      dateOfBirth: null,
    };
    // console.log('REGISTER DATA: ', registerData)
    try {
      const result = await this.$axios.$post(
        // process.env.DATA_URL + '/auth/register-customer/',
        location.protocol + '//' + location.host + '/.netlify/functions/register',
        registerData
      )
      return result
    } catch (error) {
      commit('setError', error.response.data)
      return error.response.data
    }
  },

  async requestResetPassword({
    commit
  }, data) {
    const username = data.username.trim().toLowerCase()
    console.log('REQUESTRESETPASSWORD: ', username)
    const resetData = {email:username}

    try {
      const result = await this.$axios.post(
        location.protocol + '//' + location.host + '/.netlify/functions/requestResetPassword',
        resetData
      )
      console.log('RESULT: ', result)
      return result
    } catch (error) {
      commit('setError', error.response.data)
      return error.response.data
    }

  },


  async resetPassword({
    commit
  }, data) {
    console.log('RESETPASSWORD: ', data)
    // return 'hello from store'
    try {
      const result = await this.$axios.post(
        location.protocol + '//' + location.host + '/.netlify/functions/resetPassword',
        data
      )
      console.log('RESULT: ', result)
      return result
    } catch (error) {
      commit('setError', error.response.data)
      return error.response.data
    }
  },

  // LOGOUT
  async logout({
    commit
  }) {
    localStorage.removeItem('tefl_cs_store')
    await commit('deleteUser')
  },

  // ADD NOTE
  async addNote({
    commit
  }, data) {
    console.log('ADD NOTE: ', data)
    const noteData = {
      customerId: this.state.user.user_id,
      note: data.note
    }

    try {
      const result = await this.$axios.$post(
        process.env.DATA_URL + '/customers/add_note/',
        noteData
      )
      return result
    } catch (error) {
      // commit('setError', error.response.data)
      return error.response.data
    }

  },

  // SEND EMAIL
  async sendEmail({
    commit
  }, data) {
    console.log('SEND EMAIL: ', data)
    const emailData = {
      message: data.email
    }

    try {
      const result = await this.$axios.$post(
        process.env.DATA_URL + '/sendmail/generic/',
        JSON.stringify(emailData)
      )
      return result
    } catch (error) {
      // commit('setError', error.response.data)
      return error.response.data
    }

  },

  // CLASS AGREE
  async classAgree({
    commit
  }, data) {
    console.log('CLASS AGREE: ', data)
    const classData = {
      status: data.status,
      obp_id: data.obp_id
    };

    try {
      const result = await this.$axios.$post(
        process.env.DATA_URL + '/orders/class_agree/',
        JSON.stringify(classData)
      )
      return result
    } catch (error) {
      // commit('setError', error.response.data)
      return error.response.data
    }
  },

  // Update Shipping Address
  async updateAddress({
    commit
  }, data) {
    console.log('SHIPPING ADDRESS: ', data)
    const addressData = {
      customerId: this.state.user.user_id,
      line1: data.line1,
      line2: data.line2,
      town: data.town,
      postcode: data.postcode,
      phone: data.phone,
      region: data.region,
      country: data.country
    };

    try {
      const result = await this.$axios.$post(
        process.env.DATA_URL + '/customers/set_shipping_address/',
        addressData, {
          headers: {
            'Authorization': 'Bearer ' + this.state.user.jwt_token
          },
        }
      )
      return result
    } catch (error) {
      // commit('setError', error.response.data)
      return error.response.data
    }
  },

  // Update User Details
  async updateUser({
    commit
  }, data) {
    console.log('USER DETAILS: ', data)
    const userData = {
      customerId: this.state.user.user_id,
      title: data.title,
      firstname: data.firstname,
      middlenames: data.middlenames,
      lastname: data.lastname,
      dateOfBirth: data.dateOfBirth
    };

    try {
      const result = await this.$axios.$post(
        process.env.DATA_URL + '/customers/set_names/',
        userData, {
          headers: {
            'Authorization': 'Bearer ' + this.state.user.jwt_token
          },
        }
      )
      return result
    } catch (error) {
      // commit('setError', error.response.data)
      return error.response.data
    }
  },

  // Add user names to LMS
  async updateLmsUser({
    commit
  }, data) {
    console.log('USER LMS DETAILS: ', data)
    const userData = {
      username: data.email,
      firstname: data.firstname,
      middlenames: data.middlenames,
      lastname: data.lastname
    }

    const ciphertext = CryptoJS.AES.encrypt(JSON.stringify(userData), CryptoJS.enc.Utf8.parse('My32charPasswordAndInitVectorStr'), {iv: CryptoJS.enc.Utf8.parse("My32charPassword")}).toString();
    console.log('cipher text', ciphertext)
    try {
      const result = await this.$axios.$post(
        process.env.LMS_URL + `/extorio/apis/tefl-lms-users/set_names`,
        {data:ciphertext}
      )
      return result
    }
    catch (error) {
      return error.response.data
    }
  },

  // Set user region
  async updateRegion({commit}, data) {
    await commit('setRegion',data)
  },

  // Tracking
  async updateTracking({commit}, status) {
    await commit('setTracking',status)
  },


  // Change a student's classroom (for flexible bookings)
  async changeClassroom({ commit }, data){

    console.log('changeClassroom: ',data)

    const orderId = data.order_id;
    const obpId = data.obp_id;
    const oldClassroomId = data.oldClassroomId;
    const newClassroomId = data.newClassroomId;
    const customerId = this.state.user.user_id;

    // Create the data to send
    const classData = {
      obp_id: Number(obpId),
      customer_id: Number(customerId),
      oldClassroomId: Number(oldClassroomId),
      newClassroomId: Number(newClassroomId),
      order_id: Number(orderId)
    };

    try {
      const result = await this.$axios.$post(
        process.env.DATA_URL + '/orders/changeClassroom/',
        classData
      )
      console.log('Updating classroom: ',result)
      window.location.reload()
      return result
    } catch (error) {
      return error.response.data
    }
  },

}
