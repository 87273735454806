import { render, staticRenderFns } from "./HeaderUS.vue?vue&type=template&id=ca59129c&scoped=true&"
import script from "./HeaderUS.vue?vue&type=script&lang=js&"
export * from "./HeaderUS.vue?vue&type=script&lang=js&"
import style0 from "./HeaderUS.vue?vue&type=style&index=0&id=ca59129c&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ca59129c",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {UIOpeningTimes: require('/opt/build/repo/components/UI/OpeningTimes.vue').default,CartCountdownAlt: require('/opt/build/repo/components/Cart/CountdownAlt.vue').default})
